import React,{useState,useRef} from "react";

import { useNavigate } from "react-router-dom";

import {Grid,Paper,Avatar,TextField,Button,Box,MenuItem, Typography} from "@mui/material";
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';


import axios from 'axios';
import { Form } from "react-bootstrap";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const AddMembers = () => {

   

    const paperStyle = {padding:30,  margin: "auto", width : 600, height : 'auto' }
    const HeadpaperStyle = {padding:10,  margin: "20px auto 0 auto", width:600, height : 100,backgroundColor:'#145CA4' }
    const AvatarStyle = {backgroundColor:'#0a58ca', margin: "1px auto"}
   
    const btnStyle = {margin: "5px auto 5px auto", backgroundColor:'#145CA4'}
    const textstyle = { margin:'5px 0'}
    const Navigate = useNavigate();
    
    //  today:new Date().toLocaleString().substring(0, 10),
    // today: new Date().getMonth()+"/" + new Date().getDate()+"/" + new Date().getFullYear()
    //format(new Date(), 'yyyy-MM-dd')
   
    
    const [valueOfDatePicker, setValueOfDatePicker] = useState(new Date());
    
    const [disableBtn, setDisableBtn] = useState(false);
   




    const [members,setMember] = useState({
        memberName:"",
        mobileNo: "",
        address:"",
        emailAddress:"",
        memberType:"",
        dateOfJoining: "",
        BloodGroup:"",
       
    });

  



    const onInputChange = e => {

		setMember({ ...members, [e.target.name]: e.target.value,"DateOfJoining":valueOfDatePicker,"Status":"Active" })
        
       
	};


  




    const {memberName,mobileNo,address,emailAddress,memberType,BloodGroup }  = members;
   


    const onsubmit = async (e) => {
        
      
        setDisableBtn(true);

		e.preventDefault();
		await axios.post("/members", members);
        setValueOfDatePicker (new Date());
		Navigate('/allmembers');
        setDisableBtn(true);

	    }
       

    const onKeyDownForDatePicker = (e) => {
        e.preventDefault();
     };

    

    
    return(




      


    <Grid  container>

        <Grid container>

            
            <Paper  style={HeadpaperStyle} elevation={6} >

            <Grid align="center">
            <Avatar style={AvatarStyle}> <PersonAddOutlinedIcon/> </Avatar>
       <Typography color ="white"  >Add New Member</Typography> 
        </Grid>
                
            </Paper>



        </Grid>
       
      
        
        <Paper   elevation={6}   container  style={paperStyle}  >
            

            
          

      

<Form onSubmit={e => onsubmit(e)}  >



 

        <TextField  id="standard-basic" style={textstyle} autoComplete='off' name="memberName" value={memberName} label="Full Name" placeholder="Enter your name here" variant="outlined" size="small"  required  fullWidth  onChange={e => onInputChange(e)}/>
        <TextField  id="standard-basic" style={textstyle} autoComplete='off' name="mobileNo" value={mobileNo}  label="Mobile No" placeholder="Enter mobile no" variant="outlined" size="small" fullWidth required onChange={e => onInputChange(e)}/>
        <TextField  id="standard-basic" style={textstyle} autoComplete='off' name="address" value={address} label="Address" multiline placeholder="Enter your address here" variant="outlined" size="small" fullWidth onChange={e => onInputChange(e)}/>
        <TextField  id="standard-basic" style={textstyle} autoComplete='off' name="emailAddress" value={emailAddress}  label="Email" placeholder="Enter email address" variant="outlined" size="small" fullWidth required onChange={e => onInputChange(e)}/>
        

        <Box >
            <TextField  name="BloodGroup" value={BloodGroup}  label='Blood Group' style={textstyle} variant="outlined" select size="small" fullWidth  onChange={e => onInputChange(e)} >
                <MenuItem value='A+'>A+</MenuItem>
                <MenuItem value='B+'>B+</MenuItem>
                <MenuItem value='O+'>O+</MenuItem>
                <MenuItem value='A-'>A-</MenuItem>
                <MenuItem value='B-'>B-</MenuItem>
                <MenuItem value='O-'>O-</MenuItem>
                <MenuItem value='AB+'>AB+</MenuItem>
                <MenuItem value='AB-'>AB-</MenuItem>
            </TextField>

       
       </Box>
        

       
      
       {/* <TextField style={textstyle}  type ="date" min="2017-04-01" max="2017-04-30"    name="dateOfJoining" value= {dateOfJoining}  size="small" fullWidth   onChange = {e=> onInputChange(e)} /> */}

       
      <Box >
            <TextField  name="memberType" value={memberType}  label='Member Type' style={textstyle} variant="outlined" select size="small" fullWidth required onChange={e => onInputChange(e)} >
                <MenuItem value='Founder Member'>Founder Member</MenuItem>
                <MenuItem value='Associate Member'>Associate Member</MenuItem>
                <MenuItem value='General Member'>General Member</MenuItem>
             

            </TextField>

       
       </Box>
      
       
      

       <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        
        renderInput={(props) => <TextField size="small" onKeyDown={onKeyDownForDatePicker} style={textstyle} fullWidth {...props} />}
        label="Joining Date"
        value= {valueOfDatePicker}
        
        onChange={(newValue) => {
          setValueOfDatePicker(newValue);
          
        }}

        onSelect={onInputChange}
        onKeyDown={onKeyDownForDatePicker}
      />
    </LocalizationProvider>

      

      
     
      
        <Button 
       
        type="submit" style={btnStyle} disabled={disableBtn} variant="contained" fullWidth   >Submit</Button>
      
       
        </Form>
   

        </Paper>
        
      
    </Grid>    
    
    



   
   
          
    
 
    )
}
export default AddMembers;
