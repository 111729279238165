import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid, Paper, Avatar, Link, Typography, Box, MenuItem, TextField } from "@mui/material";
import moment from "moment";
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';





const PaymentStatus = () => {


    const paperStyle = { padding: 30, margin: "auto", width: 700, height: 'auto' }
    const HeadpaperStyle = { padding: 10, margin: "20px auto 0 auto", width: 700, height: 100, backgroundColor: '#145CA4' }
    const AvatarStyle = { backgroundColor: '#0a58ca', margin: "1px auto" }

    const endofLastMonth = moment(new Date()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');


    const [members, setMember] = useState([]);
    const [startOfMonth, setStartOfMonth] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [endOfMonth, setEndOfMonth] = useState(moment().endOf('month').format('YYYY-MM-DD'));

    const [searchText, setSearchText] = useState('');


    const onInputChange = e => {

        setSearchText(`${e.target.value}`)


    };





    useEffect(() => {
        loadMembers();

    }, []);

    useEffect(() => {
        loadMembers();
    }, [searchText]);




    const loadMembers = async () => {
        const result = await axios.get(`/accounts/duesearch`, {
          params: {
            ...(searchText ? { SearchText: searchText } : {})
           
          },
         
        });
        setMember(result.data);
    
      };
    




    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    return (

        <Grid container>

            <Grid container>


                <Paper style={HeadpaperStyle} elevation={6} >

                    <Grid align="center">
                        <Avatar style={AvatarStyle}> <PaidOutlinedIcon /> </Avatar>
                        <Typography color="white"  >Payment Status</Typography>
                    </Grid>

                </Paper>



            </Grid>





            <Paper elevation={6} container style={paperStyle} fullwidth>

                <Box padding={1} paddingBottom={1} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }} >

                    <TextField style={{ width: "200px" }} name="Search" label='Search Here' variant="outlined" size="small" onChange={e => onInputChange(e)}>


                    </TextField>

                </Box>

                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer component={Paper} >
                        <Table stickyHeader aria-label="sticky table" border={1} bordercolor='#427CB6'>
                            <TableHead >
                                <TableRow >


                                    <TableCell style={{ backgroundColor: '#427CB6', color: 'white' }} width="35%">
                                        <Typography fontSize={13}> <b> Name </b></Typography>
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: '#427CB6', color: 'white' }} width="30%">
                                        <Typography fontSize={13} > <b>Paid Upto </b></Typography>
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: '#427CB6', color: 'white' }}>
                                        <Typography fontSize={13} textAlign="right"> <b> Status </b></Typography>
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {members

                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                                    .map((members) => {
                                        return (
                                            <TableRow >


                                                <TableCell>

                                                    <Typography variant="body2">
                                                        Mem ID: <b> {members.MemberID}</b>
                                                    </Typography>

                                                    <Typography color='green' variant="body2">
                                                        {members.MemberName}
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        {members.MobileNo}
                                                    </Typography>



                                                </TableCell>






                                                <TableCell >
                                                    {members.PaidUpto === null ?
                                                        <Typography variant="body2" >No Data</Typography>

                                                        :
                                                        <Typography variant="body2" color='green' > {moment(members.PaidUpto).format('MMM YYYY')} </Typography>

                                                    }

                                                </TableCell>


                                                <TableCell width={150}>



                                                    {members.PaidUpto === null ?
                                                        <Typography align="right" variant="body2" fontSize={13}>
                                                            <Link href="/paymentmethod" >Pay Now</Link>
                                                        </Typography>

                                                        :
                                                        null
                                                    }






                                                    {moment(moment(new Date()).format('MM YYYY'), "MM.YYYY").diff(moment(moment(members.PaidUpto).format('MM YYYY'), "MM.YYYY"), 'months') > 0 ?
                                                        <Typography underline variant="body2" color="red" textAlign="right" >Due {moment(moment(new Date()).format('MM YYYY'), "MM.YYYY").diff(moment(moment(members.PaidUpto).format('MM YYYY'), "MM.YYYY"), 'months') * 100}

                                                            <Typography variant="body2" fontSize={13}>
                                                                <Link href="/paymentmethod" >Pay Now</Link>
                                                            </Typography>

                                                        </Typography>

                                                        :
                                                        moment(moment(members.PaidUpto).format('MM YYYY'), "MM.YYYY").diff(moment(moment(new Date()).format('MM YYYY'), "MM.YYYY"), 'months') === 0 ?
                                                            <Typography variant="body2" color="green" textAlign="right"> Full Paid</Typography>
                                                            :
                                                            moment(moment(new Date()).format('MM YYYY'), "MM.YYYY").diff(moment(moment(members.PaidUpto).format('MM YYYY'), "MM.YYYY"), 'months') < 0 ?
                                                                <Typography variant="body2" color="green" textAlign="right"> Adv. {moment(moment(members.PaidUpto).format('MM YYYY'), "MM.YYYY").diff(moment(moment(new Date()).format('MM YYYY'), "MM.YYYY"), 'months') * 100} </Typography>
                                                                : members.PaidUpto === null ?
                                                                    null
                                                                    :
                                                                    null
                                                    }

                                                </TableCell>




                                            </TableRow>

                                        );
                                    })}

                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        style={{ backgroundColor: '#427CB6', color: 'white' }}
                        sx={{
                            ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                                "margin-top": "1em",
                                "margin-bottom": "1em"
                            }
                        }}
                        labelRowsPerPage={"Show:"}
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        count={members.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}

                    />

                </Paper>




                <Typography variant="body2">© 2023 Prishtha. Developed By <Link target="_blank" rel="noopener" underline="none" href="https://ryansoftbd.com/">Ryansoft</Link> </Typography>

            </Paper>


        </Grid>



    )
}
export default PaymentStatus;
