import React from "react";
import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import Home from "./Components/Pages/Home";
import Dashboard from "./Components/Pages/Dashboard";
import Login from "./Components/Pages/Login";
import DueList from "./Components/Pages/DueList";

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import AddMembers from "./Components/Pages/AddMembers";
import AllMembers from "./Components/Pages/AllMembers";
import EditMember from "./Components/Pages/EditMember";
import TransactionDetails from "./Components/Pages/TransactionDetails";
import Accounts from "./Components/Pages/Accounts";
import AccountCategory from "./Components/Pages/AccountCategory";
import Navbar from "./Components/Layout/Navbar";
import NavbarForRegistration from "./Components/Layout/NavbarForRegistration";
import UnderConstruction from "./Components/Pages/UnderConstruction";
import MemberRegistration from "./Components/Pages/MemberRegistration";
import PendingMembers from "./Components/Pages/PendingMembers";
import TransactionList from "./Components/Pages/TransactionList";
import PaymentStatus from "./Components/Pages/PaymentStatus";
import AllMembersForUsers from "./Components/Pages/AllMembersForUser";
import NavbarForUser from "./Components/Layout/NavbarForUser";
import PaymentMethod from "./Components/Pages/PaymentMethod";








 


function App() {
  return (


<Router>

    <div className="App">
      

<NavbarForUser/>

      <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/transactionlist" element={<TransactionList />} />
            <Route path="/paymentstatus" element={<PaymentStatus />} />
            <Route path="/paymentmethod" element={<PaymentMethod />} />
            <Route path="/allmembersforusers" element={<AllMembersForUsers />} />


          
          

      </Routes>
     
    </div>

</Router>



  );
}

export default App;
