import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import axios from 'axios';

// const accessToken='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJKV1RTZXJ2aWNlQWNjZXNzVG9rZW4iLCJqdGkiOiIxYTBkOTFhMC05OGI3LTQ0NDQtOGVjOS1iNTc2Mjg5NWY4NTciLCJpYXQiOiIwMy8xOS8yMDIzIDk6MTI6MjEgQU0iLCJEaXNwbGF5TmFtZSI6IkFyaWYgUmFpaGFuIiwiVXNlck5hbWUiOiJhZG1pbiIsImV4cCI6MTY3OTIxNzIwMSwiaXNzIjoiSldUQXV0aGVudGljYXRpb25TZXJ2ZXIiLCJhdWQiOiJKV1RTZXJ2aWNlUG9zdG1hbkNsaWVudCJ9.83chR4PcyyKqAJBP6L03SU3TDBmZp8HqOulhR7XMF5o'
axios.defaults.baseURL="http://iq.ryansoftbd.com:2023/api";
// axios.defaults.headers={Authorization: `Bearer ${accessToken}`}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

