import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";

import moment from 'moment';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


import {Grid,Paper,Avatar,Link,TextField,Button,Box, Typography, IconButton} from "@mui/material";


import axios from 'axios';

import { DashboardCustomizeOutlined } from "@mui/icons-material";


const Dashboard = () => {

    const paperStyle = {padding:15,  margin: "auto", width : 600, height : 'auto' }
    const HeadpaperStyle = {padding:10,  margin: "20px auto 0 auto", width:600, height : 100,backgroundColor:'#145CA4' }
    const AvatarStyle = {backgroundColor:'#0a58ca', margin: "1px auto"}
   
    const CardpaperStyle = {padding:3,  margin: "1px", width : 'auto', height : 'auto' }

  
    const endofLastyear = moment(new Date()).subtract(1,'years').endOf('year').format('YYYY-MM-DD');
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');
    const endofLastMonth = moment(new Date()).subtract(1,'months').endOf('month').format('YYYY-MM-DD');

    const [allMembers,setAllMember] = useState([]);
    const [activeMembers,setActiveMember] = useState([]);
    const [inactiveMembers,setInactiveMember] = useState([]);
    const [pendingMembers,setPendingMember] = useState([]);


    const [accountBalance,setAccountBalance] = useState([]);
    const [collectionAmount,setCollectionAmount] = useState([]);
    const [expenseAmount,setExpenseAmount] = useState([]);
    const [openingAmount,setOpeningAmount] = useState([]);

const navigate = useNavigate();

    const loadAllMembers = async () => {
     const result = await axios.get("/membercount");
     setAllMember (result.data[0]); 
    
 };

 
 const showAllMembers =  () => {
 
  navigate('/allmembers');
 
 
};


 
 const loadCountActiveMembers = async () => {
     const result = await axios.get("/statuscount/active");
     setActiveMember (result.data[0]); 
   
    
 };

  
 const loadCountInactiveMembers = async () => {
     const result = await axios.get("/statuscount/inactive");
     setInactiveMember (result.data[0]); 
   
    
 };

 const loadCountPendingMembers = async () => {
     const result = await axios.get("/statuscount/pending");
     setPendingMember (result.data[0]); 
   
 };
 



 
 const loadAccountBalance = async () => {
     const result = await axios.get(`/accounts/balance/2020-01-01/${moment(new Date()).format('YYYY-MM-DD')}`);
     setAccountBalance (result.data[0]); 
 };


          

 const loadCollectionAmount = async () => {
     const result = await axios.get(`/accounts/balance/${startOfMonth}/${endOfMonth}`);
     setCollectionAmount (result.data[0]);

   
 };

  
 const loadExpenseAmount = async () => {
     const result = await axios.get(`/accounts/balance/${startOfMonth}/${endOfMonth}`);
     setExpenseAmount (result.data[0]); 
   
 };

  
 const loadOpeningAmount = async () => {
  const result = await axios.get(`/accounts/balance/${endofLastyear}/${endofLastMonth}`);
  setOpeningAmount (result.data[0]); 

};

  




 useEffect(()=>{
     loadAllMembers();
     loadCountActiveMembers();
     loadCountInactiveMembers();
     loadCountPendingMembers();
     loadAccountBalance();
     loadCollectionAmount();
     loadExpenseAmount();
     loadOpeningAmount();
 },[]);

    
    return(




      


    <Grid  container>
 
 
        <Grid container>

            
            <Paper  style={HeadpaperStyle} elevation={6} >

            <Grid align="center">
            <Avatar style={AvatarStyle}> <DashboardCustomizeOutlined/> </Avatar>
       <Typography color ="white"  >Dashboard</Typography> 
        </Grid>
                
            </Paper>



        </Grid>
       
     
 
            

            

<Paper    elevation={4}   container  style={paperStyle}  >



    <Card onClick={()=>{navigate('/allmembers')}} style={CardpaperStyle}  elevation={4} sx={{ display: 'flex', justifyContent: 'center' }}>
    
      <Box   sx={{ display: 'flex' , flexDirection: 'column', border:0}} >
        <CardContent sx={{ flex: '1 0 auto' }}>

                        <Typography variant="body2" textAlign='center' component="div" color="#04396C">
                             Total Members 
                        </Typography>
                        <Typography variant="body3" textAlign='center' component="div" color="blue">
                             <b> {allMembers.TotalMemberCount} </b>
                        </Typography>
                     
        </CardContent>
      </Box>        
        </Card>
     



    
<Card  style={CardpaperStyle}  elevation={0} sx={{ display: 'flex', justifyContent: 'center' }}>
    <Card onClick={()=>{navigate('/pendingmembers')}} style={CardpaperStyle}  elevation={4} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box   sx={{ display: 'flex' , flexDirection: 'column', border:0}} >
        <CardContent sx={{ flex: '1 0 auto' }}>

                        <Typography variant="body2" textAlign='center' component="div" color="#04396C">
                             Pending 
                        </Typography>


                        <Typography variant="body3" textAlign='center' component="div" color="purple">
                             <b>{pendingMembers.TotalMemberCount}</b> 
                        </Typography>

                        
        </CardContent>
      </Box>        
        </Card>


        <Card onClick={()=>{navigate('/allmembers')}} style={CardpaperStyle}  elevation={4} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box  sx={{ display: 'flex' , flexDirection: 'column', border:0}} >
        <CardContent sx={{ flex: '1 0 auto' }}>

                        <Typography variant="body2" textAlign='center' component="div" color="#04396C">
                             Active
                        </Typography>
                        <Typography variant="body3" textAlign='center' component="div" color="green">
                             <b> {activeMembers.TotalMemberCount} </b> 
                        </Typography>

                     
        </CardContent>
      </Box>        
        </Card>

        <Card  style={CardpaperStyle}  elevation={4} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box   sx={{ display: 'flex' , flexDirection: 'column', border:0}} >
        <CardContent sx={{ flex: '1 0 auto' }}>

                        <Typography variant="body2" textAlign='center' component="div" color="#04396C">
                             Inactive
                        </Typography>
                        <Typography variant="body3" textAlign='center' component="div" color="red">
                             <b>{inactiveMembers.TotalMemberCount}</b> 
                        </Typography>
                     
        </CardContent>
      </Box>        
        </Card>
    </Card>


    <Typography variant="body3" component="div"  textAlign='center' >Accounts</Typography>
    <Typography variant="caption" component="div"  textAlign='center' >(This Month)</Typography>

   
   
    
    <Card  style={CardpaperStyle}  elevation={0} sx={{ display: 'flex', justifyContent: 'center' }}>

      


    <Card onClick={()=>{navigate('/transactionlist')}} style={CardpaperStyle}  elevation={4} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box   sx={{ display: 'flex' , flexDirection: 'column', border:0}} >
        <CardContent sx={{ flex: '1 0 auto' }}>

                        <Typography variant="body2" textAlign='center' component="div" color="#04396C">
                             Opening
                        </Typography>


                        {openingAmount.Balance === null ?
                         <Typography variant="body2" textAlign='center' component="div" color="#008000">
                           <b>0</b> 
                        </Typography>
                        : 
                        <Typography variant="body2" textAlign='center' component="div" color="#008000">
                          <b>{openingAmount.Balance}</b> 
                        </Typography>
                        }

                        
        </CardContent>
      </Box>        
      

</Card>
   
    
    <Card onClick={()=>{navigate('/transactionlist')}} style={CardpaperStyle}  elevation={4} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box  sx={{ display: 'flex' , flexDirection: 'column', border:0}} >
        <CardContent sx={{ flex: '1 0 auto' }}>

                        <Typography variant="body2" textAlign='center' component="div" color="#04396C">
                             Collection
                        </Typography>
                                             
                      
                        {collectionAmount.CollectedAmount === null ?
                         <Typography variant="body2" textAlign='center' component="div" color="#008000">
                           <b>0</b> 
                        </Typography>
                        : 
                       
                        <Typography variant="body2" textAlign='center' component="div" color="#008000">
                             <b>{collectionAmount.CollectedAmount}</b> 
                        </Typography>
                        }

                 
        </CardContent>
      </Box>        
       
</Card>

      
<Card onClick={()=>{navigate('/transactionlist')}} style={CardpaperStyle}  elevation={4} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box   sx={{ display: 'flex' , flexDirection: 'column', border:0}} >
        <CardContent sx={{ flex: '1 0 auto' }}>

                        <Typography variant="body2" textAlign='center' component="div" color="#04396C">
                             Expense
                        </Typography>



                     {expenseAmount.ExpenseAmount === null ?
                         <Typography variant="body2" textAlign='center' component="div" color="#008000">
                           <b>0</b> 
                        </Typography>
                        : 
                        <Typography variant="body2" textAlign='center' component="div" color="red">
                             <b>{expenseAmount.ExpenseAmount}</b> 
                        </Typography>
                   
                        }
                     
        </CardContent>
      </Box>        
      

</Card>


        
    </Card>

   
   
    <Card style={CardpaperStyle}  elevation={4} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box   sx={{ display: 'flex' , flexDirection: 'column', border:0}} >
        <CardContent sx={{ flex: '1 0 auto' }}>

                        <Typography variant="body2" textAlign='center' component="div" color="#04396C">
                             Current Balance
                        </Typography>

                        <Typography borderRadius={1} backgroundColor='#145CA4' color='white' variant="body3" textAlign='center' component="div" >
                            {accountBalance.Balance} Tk 
                        </Typography>
                        
        </CardContent>
      </Box>    
          
    </Card>
    <Typography align="center" variant="body2">© 2023 Prishtha. Developed By <Link href="https://www.ryansoftbd.com/" target="_blank" rel="noopener" underline="none" >Ryansoft</Link> </Typography>


          
   
   

        </Paper>
        
      
    </Grid>    
    
    



   
   
          
    
 
    )
}
export default Dashboard;
