import React, { useState } from "react";

import { Grid, Paper, Avatar, Button, Box, Typography,Link } from "@mui/material";
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import Tooltip from '@mui/material/Tooltip';

import bkash from '../Images/bkash.png';

import copy from 'copy-to-clipboard';

const PaymentMethod = () => {

    const HeadpaperStyle = { padding: 10, margin: "20px auto 0 auto", width: 500, height: 100, backgroundColor: '#145CA4' }
    const paperStyle = { padding: 30, margin: "auto", width: 500, height: 'auto' }

    const AvatarStyle = { backgroundColor: '#0a58ca', margin: "1px auto" }



    const [clicked, setClicked] = useState(false)
   
    
    const bkashNumber = '01674058180'
  
   const  copyToClipboard = () => {


        copy(bkashNumber)
        setClicked(true);
        
    }



    return (


        <Grid container>

            <Grid container>


                <Paper style={HeadpaperStyle} elevation={6} >

                    <Grid align="center">
                        <Avatar style={AvatarStyle}> <CurrencyExchangeOutlinedIcon /> </Avatar>
                        <Typography color="white"  >Payment Method</Typography>
                    </Grid>

                </Paper>



            </Grid>





            <Paper elevation={3} container style={paperStyle}  >

                <Typography align='center' variant="h6">bKash Number </Typography>
                <Typography paddingBottom={2} align='center' variant="body2" fontSize={12} ><b> (Please don't forget to type your Member ID in the Reference field) </b> </Typography>

                <Box align="center">
                    <Typography align='center' backgroundColor='#145CA4' color='white' variant="h6"><b>{bkashNumber}</b></Typography>

                    {clicked ?
                       
                        <Button color="primary" onClick={copyToClipboard}> <b> Copied </b> </Button>

                   
                        :
                       
                        <Button color="primary" onClick={copyToClipboard}> <b>copy</b></Button>

                   
                    }


                </Box>


                <Box marginTop={2} align="center">
                    <img src={bkash} style={{ width: 300, height: 572 }} alt="Logo" />
                </Box>

                <Typography  align="center" variant="body2">© 2023 Prishtha. Developed By <Link target="_blank" rel="noopener" underline="none" href="https://ryansoftbd.com/">Ryansoft</Link> </Typography>

            </Paper>



           


        </Grid>










    )
}
export default PaymentMethod;
