import React from "react";
import { NavLink } from "react-router-dom";
import { Button,Typography,Grid,Avatar,Box } from  "@mui/material";
import Menu from '@mui/material/Menu';

import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import MenuItem from '@mui/material/MenuItem';




const NavbarForUser = () => {


  const [anchorElAccount, setAnchorElAccount] = React.useState(null);
  const [anchorElMember, setAnchorElMember] = React.useState(null);
  const openAccount = Boolean(anchorElAccount);
  const openMember = Boolean(anchorElMember);


  const handleClickForAccount = (event) => {
    setAnchorElAccount(event.currentTarget);
  };

  
  const handleClickForMember = (event) => {
    setAnchorElMember(event.currentTarget);
  };

  const handleCloseAccount = () => {
    setAnchorElAccount(null);
  };

  const handleCloseMember = () => {
    setAnchorElMember(null);
  };
  
  const navcolor = {backgroundColor:'#1976D2'}
  return(
    
    <div >
<nav className="navbar navbar-expand-md navbar-dark   "  style={navcolor} display="flex-end"   >
 

  <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.2rem',
              color: '#FFFFFF',
              textDecoration: 'none',
              pl:3
            }}
          >
            <RequestQuoteOutlinedIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            Dipositor
          </Typography>
  <Button 
   
  className="navbar-toggler" type="Button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </Button>

  <Grid className="collapse navbar-collapse" id="navbarSupportedContent" >
    <ul className="navbar-nav mr-auto" >

   
    <Button   sx={{  color: 'white', display: 'block', marginLeft:'15px' }} component={NavLink} to="/" >
     
 Home
    </Button>
    

    
    
    <Button   sx={{  color: 'white', display: 'block', marginLeft:'15px' }} component={NavLink} 
     aria-controls={openMember ? 'basic-menu' : undefined}
     aria-haspopup="true"
     aria-expanded={openMember ? 'true' : undefined}
     onClick={handleClickForMember}
     onMouseOver={handleClickForMember}
     onMouseOut={handleClickForMember}
     
      >
     
 Member
    </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorElMember}
        open={openMember}
        onClose={handleCloseMember}
        
      
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleCloseMember} ><NavLink className="nav-link" to ="/allmembersforusers">Member List</NavLink></MenuItem>
        <MenuItem onClick={handleCloseMember}><NavLink className="nav-link" to ="/paymentstatus">Payment Status</NavLink></MenuItem>
      </Menu>

      
     
    <Button   sx={{  color: 'white', display: 'block', marginLeft:'15px' }} component={NavLink} 
     aria-controls={openAccount ? 'basic-menu' : undefined}
     aria-haspopup="true"
     aria-expanded={openAccount ? 'true' : undefined}
     onClick={handleClickForAccount}
     onMouseOver={handleClickForAccount}
      >
      Accounts
    </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorElAccount}
        open={openAccount}
        onClose={handleCloseAccount}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
       <MenuItem onClick={handleCloseAccount}><NavLink className="nav-link" to ="/transactionlist">Transaction List</NavLink></MenuItem>
        <MenuItem onClick={handleCloseAccount}><NavLink className="nav-link" to ="/paymentmethod">Payment Method</NavLink></MenuItem>
        
      </Menu>
      
  </ul>

   




  </Grid>
  

  <Box  marginRight={1} paddingRight={1}   sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end'}} >

  {/* <img src={logo}  style={{width: 50, height: 50, borderRadius: 50/ 2}} alt="Logo" /> */}
  
  <Typography
            variant="h6"
            noWrap
            component="a"
           
            sx={{
              mr: 2,
              ml:1,
              display: { xs: 'flex', md: 'flex' },
              fontFamily: 'Futura',
              fontWeight: 500,
              letterSpacing: '.1rem',
              color: '#FFFFFF',
              textDecoration: 'none',
            padding:1
           
              
            }}
          >
            
              
        IQ Muslimin
  
           
          </Typography>
</Box>
  

  
</nav>

</div>
)
};


export default NavbarForUser;
