import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Grid, Paper, Avatar, Typography, TextField } from "@mui/material";
import ContactsIcon from '@mui/icons-material/Contacts';
import Box from '@mui/material/Box';



import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

import IconButton from '@mui/material/IconButton';
import EditButton from '@mui/icons-material/Edit';
import DeleteButton from '@mui/icons-material/Delete';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import Tooltip from '@mui/material/Tooltip';

import moment from 'moment';


import Button from '@mui/material/Button';
import DialogBox from "../DialogBox";

import Fab from '@mui/material/Fab';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.dark, 0.95),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.dark, 0.95),
  },
  marginLeft: 25,
  marginRight: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: "#FFFFFF"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#FFFFFF',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
        
      },
    },
  },
}));


const AllMembers = () => {

  const paperStyle = { padding: 30, margin: "auto", width: 700, height: 'auto' }
  const CardpaperStyle = { padding: 10, margin: "auto", width: 'auto', height: 'auto' }
  const HeadpaperStyle = { padding: 10, margin: "20px auto 0 auto", width: 700, height: 100, backgroundColor: '#145CA4' }


  const AvatarStyle = { backgroundColor: '#0a58ca', margin: "1px auto" }






  const [openDelete, setOpenDelete] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [searchText, setSearchText] = useState('');


  const onInputChange = e => {
    setSearchText(`${e.target.value}`)
  };


  const handleOpenView = () => {
    setOpenView(true);
  };


  const handleCloseView = () => {
    setOpenView(false);
  };


  const handleOpenDelete = () => {
    setOpenDelete(true);
  };


  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const [members, setMember] = useState([]);
  const [viewMember, setViewMember] = useState([]);
  


  useEffect(() => {
    loadMembers();
  }, [searchText]);


  const loadMembers = async () => {
    const result = await axios.get(`/members`, {
      params: {
        ...(searchText ? { SearchText: searchText } : {})
       
      },
     
    });
    setMember(result.data);

  };





  const DeleteMember = async (id) => {

    await axios.delete(`/members/${id}`);

    handleCloseDelete();
    loadMembers();




  };





  const ViewMember = async (id) => {

    const result = await axios.get(`/members/${id}`);
    setViewMember(result.data[0]);
    handleOpenView();


  };


  const ViewMemberForDelete = async (id) => {

    const result = await axios.get(`/members/${id}`);
    setViewMember(result.data[0]);
    handleOpenDelete();



  };


  return (


    <Grid container>


      <Grid container>


        <Paper style={HeadpaperStyle} elevation={6} >

          <Grid align="center">

            <Avatar style={AvatarStyle}> <ContactsIcon /> </Avatar>

            <Typography color="white"  >Member List</Typography>

          </Grid>

        






        </Paper>



      </Grid>



      <Paper elevation={6} container style={paperStyle} fullwidth>




        <Box padding={1} paddingBottom={1} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >

          <Tooltip title="Add Member" placement="top-start">
            <Fab size="small" component={Link} to="/addmembers" color="primary" aria-label="add">
              <AddOutlinedIcon />
            </Fab>
          </Tooltip>

          <Search >
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              onChange={e => onInputChange(e)}           
              inputProps={{ 'aria-label': 'search'}}
             
            />
          </Search>
          
        
          
 
   
        </Box>


     
          
        
        

         

        <table class="table">

          <tbody>
            {
              members.map((members, index) => (

                <tr>






                  <Paper elevation={4} container style={CardpaperStyle}  >


                    <Card elevation={4} sx={{ display: 'flex' }}>


                      <Box sx={{ display: 'flex', flexDirection: 'row', border: 0 }} >
                        <CardContent sx={{ flex: '1 0 auto' }}>



                          <Typography variant="h6" component="div" color="#04396C">
                            {members.MemberName}
                          </Typography>

                          <Typography variant="body3" component="div" color="#008000">
                            {members.MemberType}
                          </Typography>

                          <Typography variant="body3">
                            Member ID:  {members.MemberID}
                          </Typography>
                          <Typography variant="body2">
                            {members.EmailAddress}
                          </Typography>
                          <Typography variant="body2">
                            Blood Group: {members.BloodGroup}
                          </Typography>


                          <Typography variant="body2" color="text.secondary">
                            Member Since {moment(members.DateOfJoining).format('MMM YYYY')}
                          </Typography>






                          <Grid container justifyContent="flex">
                            <Box padding={0} sx={{ display: 'flex', flexDirection: 'row', border: 0 }} >



                              <Tooltip title="View Details" placement="top-start">
                                <IconButton color="primary" aria-label="Dialog Box" component="label" >
                                  <Link onClick={() => ViewMember(members.MemberID)} > <VisibilityOutlinedIcon /> </Link>
                                </IconButton>
                              </Tooltip>



                              <DialogBox



                                content={

                                  <Card elevation={6} >

                                    <CardContent>



                                      <Typography variant="h6" color="#04396C">
                                        {viewMember.MemberName}
                                      </Typography>

                                      <Typography variant="body3" component="div" color="#008000">
                                        {viewMember.MemberType}
                                      </Typography>

                                      <Typography variant="body2">
                                        Member ID: {viewMember.MemberID}
                                      </Typography>

                                      <Typography fontWeight='bold' variant="body2">
                                        {viewMember.MobileNo}
                                      </Typography>
                                      <Typography variant="body2">
                                        {viewMember.Address}
                                      </Typography>
                                      <Typography variant="body2">
                                        {viewMember.EmailAddress}
                                      </Typography>
                                      <Typography variant="body2">
                                        {viewMember.BloodGroup}
                                      </Typography>
                                      <Typography variant="body2">
                                        {viewMember.memberType}
                                      </Typography>

                                      <Typography fontWeight='bold' variant="body2">
                                        {viewMember.Status}
                                      </Typography>



                                      <Typography variant="body2" color="text.secondary">
                                        Member Since {moment(viewMember.DateOfJoining).format('MMM YYYY')}
                                      </Typography>

                                    </CardContent>

                                  </Card>
                                }




                                isOpen={openView}
                                handleClose={handleCloseView}
                                noButton={<Button onClick={handleCloseView} autoFocus>Close </Button>}
                              />






                              <Tooltip title="Edit Member" placement="top-start">
                                <IconButton color="primary" aria-label="Edit Member" component="label">
                                  <Link to={`/editmember/${members.MemberID}`}>  <EditButton /> </Link>
                                </IconButton>
                              </Tooltip>




                              <Tooltip title="Delete Member" placement="top-start">
                                <IconButton color="primary" aria-label="Dialog Box" component="label" >
                                  <Link onClick={() => ViewMemberForDelete(members.MemberID)} > <DeleteButton /> </Link>
                                </IconButton>
                              </Tooltip>



                              <DialogBox
                                title="Delete Member"
                                content="Do you really want to delete this member?"
                                isOpen={openDelete}
                                handleClose={handleCloseView}
                                yesButton={<Button variant="contained" onClick={() => DeleteMember(viewMember.MemberID)}>Delete</Button>}
                                noButton={<Button onClick={handleCloseDelete} autoFocus>Close </Button>}
                              />




                            </Box>


                          </Grid>


                        </CardContent>

                      </Box>




                    </Card>



                  </Paper>



                </tr>

              ))
            }



          </tbody>
        </table>





      </Paper>


    </Grid>



  )
}
export default AllMembers;
