import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid, Paper, Avatar, Typography, Link, Box, Button, MenuItem, TextField } from "@mui/material";
import moment from "moment";
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';



import DialogBox from "../DialogBox";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { toDate } from "date-fns";



const TransactionList = () => {


  const paperStyle = { padding: 30, margin: "auto", width: 700, height: 'auto' }
  const HeadpaperStyle = { padding: 10, margin: "20px auto 0 auto", width: 700, height: 100, backgroundColor: '#145CA4' }
  const AvatarStyle = { backgroundColor: '#0a58ca', margin: "1px auto" }

  const endofLastMonth = moment(new Date()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');


  const [accounts, setAccount] = useState([]);
  const [startOfMonth, setStartOfMonth] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endOfMonth, setEndOfMonth] = useState(moment().endOf('month').format('YYYY-MM-DD'));


  const [FromDateValue, setFromDateValue] = useState(new Date());
  const [ToDateValue, setToDateValue] = useState(new Date());

  const [show, setShow] = useState(false);



  const [openView, setOpenView] = useState(false);



  const handleSetShow = () => {

    setShow(true);
    setOpenView(true);


  };


  useEffect(() => {
    loadAccounts();

  }, []);

  useEffect(() => {
    loadAccounts();
  }, [startOfMonth], [endOfMonth]);



  const loadAccounts = async () => {
    const result = await axios.get(`/accounts/accwithopening/${startOfMonth}/${endOfMonth}`);
    setAccount(result.data);
  };


  const viewLastMonth = () => {

    setStartOfMonth(moment(new Date()).subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
    setEndOfMonth(moment(new Date()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
    setPage(0);
    setRowsPerPage(10);
    setShow(false);
  }

  const viewThisMonth = () => {

    setStartOfMonth(moment().startOf('month').format('YYYY-MM-DD'));
    setEndOfMonth(moment().endOf('month').format('YYYY-MM-DD'));
    setPage(0);
    setRowsPerPage(10);
    setShow(false);

  }

  const handleCustomeSearch = () => {

    setStartOfMonth(moment(FromDateValue.toString()).format('YYYY-MM-DD'));
    setEndOfMonth(moment(ToDateValue.toString()).format('YYYY-MM-DD'));
    setPage(0);
    setRowsPerPage(10);
    handleCloseDialog();




  };



  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };




  const handleCloseDialog = () => {

    setOpenView(false);


  };


  return (
    <>
      <Grid container>

        <Grid container>


          <Paper style={HeadpaperStyle} elevation={6} >

            <Grid align="center">
              <Avatar style={AvatarStyle}> <PaidOutlinedIcon /> </Avatar>
              <Typography color="white"  >Transaction Details</Typography>
            </Grid>

          </Paper>



        </Grid>


        <Paper elevation={6} style={paperStyle} fullwidth>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', pt: 1, pb: 1 }} >

            <TextField style={{ width: "150px" }} name="Search" label='Search' variant="outlined" select size="small"  >
              <MenuItem value='Last Month' onClick={viewLastMonth}>Last Month</MenuItem>
              <MenuItem value='This Month' onClick={viewThisMonth}>This Month</MenuItem>
              <MenuItem value='Custom' onClick={handleSetShow}>Custom</MenuItem>

            </TextField>

          </Box>




          {show &&




            <Box sx={{ display: 'flex', flexDirection: ' column', alignItems: 'flex-end' }} >









              <DialogBox



                content={

                  <Box width={200}
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                      p: 1,



                    }}
                  >

                    <Box margin={1}>


                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          inputFormat="DD-MMM-YYYY"



                          renderInput={(props) => <TextField size="small" style={{ width: "180px" }}    {...props} />}
                          label="From Date"
                          value={FromDateValue}

                          onChange={(newValue) => {
                            setFromDateValue(moment(newValue.toString()).format("YYYY-MM-DD"));

                          }}

                        />
                      </LocalizationProvider>

                    </Box>

                    <Box margin={1}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>


                        <DatePicker
                          inputFormat="DD-MMM-YYYY"

                          renderInput={(props) => <TextField size="small" style={{ width: "180px" }}   {...props} />}
                          label="To Date"
                          value={ToDateValue}

                          onChange={(newValue) => {
                            setToDateValue(moment(newValue.toString()).format("YYYY-MM-DD"));

                          }}

                        />
                      </LocalizationProvider>
                    </Box>


                  </Box>


                }




                isOpen={openView}
                handleClose={handleCloseDialog}
                noButton={<Button onClick={handleCustomeSearch} autoFocus>search </Button>}
                yesButton={<Button onClick={handleCloseDialog} autoFocus>Close </Button>}

              />







            </Box>



          }





          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer component={Paper}>
              <Table stickyHeader aria-label="sticky table" border={1} bordercolor='#427CB6'>
                <TableHead>
                  <TableRow>


                    <TableCell style={{ backgroundColor: '#427CB6', color: 'white' }} >
                      <Typography>Description</Typography>
                    </TableCell>
                    <TableCell style={{ backgroundColor: '#427CB6', color: 'white' }} >
                      <Typography textAlign="right">Amount</Typography>
                    </TableCell>
                    <TableCell style={{ backgroundColor: '#427CB6', color: 'white' }} >
                      <Typography textAlign="right">Balance</Typography>
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>

                  {accounts

                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                    .map((accounts) => {
                      return (
                        <TableRow >


                          <TableCell>

                            <Typography variant="body2">
                              <b> {moment(accounts.AccountDate).format('DD-MMM-YYYY')}</b>
                            </Typography>
                            {accounts.DebitedAmount === 0 ?
                              <Typography variant="body2" color="red"   > {accounts.CategoryName}</Typography>

                              :
                              <Typography variant="body2" color="#007500" >{accounts.CategoryName}</Typography>
                            }


                            {accounts.MemberName === null ?
                              null

                              :
                              <Typography variant="body2" color="black" > {accounts.MemberName}</Typography>
                            }

                            {accounts.Remarks === null ?
                              null

                              :
                              <Typography variant="body2" color="black" > Note: {accounts.Remarks} </Typography>
                            }

                          </TableCell>






                          <TableCell>
                            {accounts.DebitedAmount === 0 ?
                              <Typography variant="body2" color="red" textAlign='right'  >{accounts.CreditedAmount}</Typography>
                              :
                              <Typography variant="body2" color="green" textAlign='right' >{accounts.DebitedAmount}</Typography>
                            }
                          </TableCell>


                          <TableCell>
                            <Typography variant="body2" color="Black" textAlign='right'  >{accounts.Balance}</Typography>
                          </TableCell>




                        </TableRow>

                      );
                    })}

                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              style={{ backgroundColor: '#427CB6', color: 'white' }}
              sx={{
                ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                  "margin-top": "1em",
                  "margin-bottom": "1em"
                }
              }}
              labelRowsPerPage={"Show:"}
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={accounts.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

          </Paper>



          <Typography variant="body2">© 2023 Prishtha. Developed By <Link target="_blank" rel="noopener" underline="none" href="https://ryansoftbd.com/">Ryansoft</Link> </Typography>

        </Paper>

      </Grid>


    </>
  )
}
export default TransactionList;
